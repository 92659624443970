(function($){
    $(function(){

        var $parallaxBB = $('.apus-parallax-billboard');
        
        if ($parallaxBB.length) {
            var viewHeight = document.documentElement.clientHeight,
                headerHeight = $('#apus-header').outerHeight();
                midpoint = (viewHeight/2) + headerHeight,
                quarterpoint = (viewHeight/4),
                actClass = 'apus-active',
                passClass = 'apus-passed',
                botpoint = quarterpoint*.35,
                toppoint = quarterpoint*1.25;

            $(window).on('resize', function(){
                viewHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
                viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                midpoint = (viewHeight/2) + headerHeight;

                if (viewWidth < 768) {
                    botpoint = quarterpoint*.35;
                    toppoint = quarterpoint*1.25;
                } else {
                    botpoint = quarterpoint*.25,
                    toppoint = quarterpoint*1.15;
                }
            });

            makeSticky({
                "stickyClass": "apus-fixed-stage",
                "stickyWrapClass": "apus-parallax-billboard"
            });
              

            $parallaxBB.each(function(){
                var $thisBB = $(this),
                    $sections = $thisBB.find('.apus-section'),
                    $media = $thisBB.find('.apus-media'),
                    $context = $thisBB.find('.apus-context');

                $(window).on('scroll', function(){
                    $sections.each(function(i){
                        var $this = $(this),
                            topPos = this.getBoundingClientRect().top,
                            relatedMedia = $($media[i]),
                            relatedContext = $($context[i]);
        
                        if (topPos <= (midpoint + botpoint)) {
                            if (topPos <= (midpoint - toppoint)) {
                                $this.removeClass(actClass);
                                $this.addClass(passClass);
                            } else {
                                $this.addClass(actClass);
                                relatedMedia.addClass(actClass);
                                $this.removeClass(passClass);
                            }

                            relatedContext.addClass(actClass);

                            if (relatedContext.prev('.apus-context').hasClass(actClass)) {
                                relatedContext.prev('.apus-context').removeClass(actClass)
                            }
        
                        } else {
                            $this.removeClass(actClass);
                            relatedMedia.removeClass(actClass);
                            relatedContext.removeClass(actClass);
                        }
                    });
                });
            });
        }
    });  
})(jQuery);
