(function($){
    $(function(){
        var $sliderPaging = $('.carousel .apus-slider-paging');

        if ($sliderPaging.length) {
            $sliderPaging.each(function(){
                var $this = $(this),
                    $thisSlider = $this.closest('.carousel'),
                    thisSliderID = $thisSlider.attr('id'),
                    $slide = $thisSlider.find('.carousel-item');

                $this.append('<span class="apus-sp-current">1</span>/<span class="apus-sp-total">'+$slide.length+'</span>');

                var $spCurrent = $this.find('.apus-sp-current');

                $('#' + thisSliderID).on('slid.bs.carousel', function(){
                    var $activeSlide = $thisSlider.find('.carousel-item.active'),
                         activeIndex = $slide.index( $activeSlide ) + 1;

                    $spCurrent.html(activeIndex);
                });
            });
        }

        var $sliderIndicators = $('.carousel-indicators.apus-slider-indicators');

        if ($sliderIndicators.length) {
            $sliderIndicators.each(function(){
                var $this = $(this),
                    $thisSlider = $this.closest('.carousel'),
                    thisSliderID = $thisSlider.attr('id'),
                    $indicator = $this.find('li')
                    total = $indicator.length;
                
                $indicator.each(function(){
                    var $that = $(this),
                        indiNum = parseInt($that.attr('data-slide-to')) + 1;
                    
                    if ($that.hasClass('active')) {
                        $that.attr('aria-pressed', 'true');
                    } else {
                        $that.attr('aria-pressed', 'false');
                    }

                    $that.attr('role', 'button').attr('tabindex', '0').attr('aria-label', 'Slide '+ indiNum +' of '+ total).on('keyup.access', function(e){
                        var keyCode = e.which;
                        if ((keyCode === 13) || (keyCode === 32)){
                            $(this).trigger('click');
                        }
                    });
                });

                $('#' + thisSliderID).on('slid.bs.carousel', function(){
                    $indicator.attr('aria-pressed', false);
                    $this.find('li.active').attr('aria-pressed', 'true');
                });
            });
        }
    });  
})(jQuery);