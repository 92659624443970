(function($){
    $(function(){
        var $breadcrumbs = $('.apus-breadcrumbs');

        if ($breadcrumbs.length) {
            var rewriteArray = [
                    {src: 'aboutus', to: 'About Us'},
                    {src: 'aboutus-stage', to: 'About Us'},
                    {src: 'academic-leadership', to: 'Academic Leadership'},
                    {src: 'degrees-and-programs', to: 'Degrees and Programs'},
                    {src: 'faculty', to: 'Faculty'},
                    {src: 'partners', to: 'Partners'},
                    {src: 'resources', to: 'Resources'},
                    {src: 'schools', to: 'Schools'},
                    {src: 'admissions', to: 'Admissions'},
                    {src: 'admissions-stage', to: 'Admissions'},
                    {src: 'advising', to: 'Advising'},
                    {src: 'advising-stage', to: 'Advising'},
                    {src: 'alumni', to: 'Alumni'},
                    {src: 'alumni-stage', to: 'Alumni'},
                    {src: 'calc', to: 'Calculator'},
                    {src: 'calc-stage', to: 'Calculator'},
                    {src: 'career-services-stage', to: 'Calculator'},
                    {src: 'cc', to: 'Community College'},
                    {src: 'commencement', to: 'Commencement'},
                    {src: 'commencement-stage', to: 'Commencement'},
                    {src: 'course-schedule', to: 'Course Schedule'},
                    {src: 'course-schedule-stage', to: 'Course Schedule'},
                    {src: 'help', to: 'Help'},
                    {src: 'help-stage', to: 'Help'},
                    {src: 'rfi', to: 'Request for Information'},
                    {src: 'rfi-stage', to: 'Request for Information'},
                    {src: 'student-activities', to: 'Student Activities'},
                    {src: 'student-activities-stage', to: 'Student Activities'},
                    {src: 'student-record', to: 'Student Record'},
                    {src: 'student-record-stge', to: 'Student Record'},
                    {src: 'transfer-credit', to: 'Transfer Credit'},
                    {src: 'transfer-credit-stage', to: 'Transfer Credit'},
                    {src: 'tuition-and-financing', to: 'Tuition and Financing'},
                    {src: 'tuition-and-financing-stage', to: 'Tuition and Financing'},
                    {src: 'about', to: 'About'},
                    {src: 'about-stage', to: 'About'},
                    {src: 'academic-community', to: 'Academic Community'},
                    {src: 'academic-community-stage', to: 'Academic Community'},
                    {src: 'ctl', to: 'Center for Teaching and Learning'},
                    {src: 'ctl-stage', to: 'Center for Teaching and Learning'},
                    {src: 'events', to: 'Events'},
                    {src: 'events-stage', to: 'Events'},
                    {src: 'get-involved', to: 'Get Involved'},
                    {src: 'get-involved-stage', to: 'Get Involved'},
                    {src: 'military', to: 'Military'},
                    {src: 'military-stage', to: 'Military'},
                    {src: 'social-media-center', to: 'Social Media Center'},
                    {src: 'social-media-center-stage', to: 'Social Media Center'},
                    {src: 'social-policy', to: 'Social Policy'},
                    {src: 'social-policy-stage', to: 'Social Policy'},
                    {src: 'terms', to: 'Terms'},
                    {src: 'terms-stage', to: 'Terms'},
                    {src: 'programs', to: 'Programs'},
                    {src: 'rep', to: 'Representative'},
                    {src: 'rep-stage', to: 'Representative'},
                    {src: 'newsroom', to: 'Newsroom'},
                    {src: 'newsroom-stage', to: 'Newsroom'},
                    {src: 'coronavirus-stage', to: 'Coronavirus'},
                    {src: 'coronavirus', to: 'Coronavirus'},
                    {src: 'internships-and-fellowships', to: 'Internships and Fellowships'},
                    {src: 'partners', to: 'Partners'},
                    {src: 'partners-stage', to: 'Partners'},
                    {src: 'wreaths', to: 'Wreaths'},
                    {src: 'wreaths-stage', to: 'Wreaths'},
                    {src: 'studentstories', to: 'Student Stories'},
                    {src: 'studentstories-stage', to: 'Student Stories'},
                    {src: 'NSSE2019', to: 'National Survey of Student Engagement'},
                    {src: 'NSSE2019-stage', to: 'National Survey of Student Engagement'},
                    {src: 'info', to: 'Info'},
                    {src: 'info-stage', to: 'Info'},
                    {src: 'hlc', to: 'HLC Visit'},
                    {src: 'hlc-stage', to: 'HLC Visit'},
                    {src: 'cookies', to: 'Cookies'},
                    {src: 'cookies-stage', to: 'Cookies'},
                    {src: 'privacy', to: 'Privacy'}
                ],
                $bcLinks = $breadcrumbs.find('a.breadcrumb');

            $bcLinks.each(function(){
                var $this = $(this),
                    text = $this.text();
                
                for (var i = 0; i < rewriteArray.length; i++) {
                    if (text === rewriteArray[i].src) {
                        $this.html(rewriteArray[i].to);
                        break;
                    }
                }
            });
        }
    });
})(jQuery);