(function($){
    $(function(){
        var $nextCourseData = $('.apus-next-courses-data');

        if ($nextCourseData.length) {
            $nextCourseData.each(function(){
                var $this = $(this),
                $regBy = $this.find('.apus-next-courses-data-reg-by'),
                $start = $this.find('.apus-next-courses-data-start'),
                endpoint = $this.data('endpoint'),
                datestype = $this.data('datestype');

                

                if(typeof endpoint !== 'undefined' && typeof datestype !== 'undefined'){
                    $.ajax({
                        url: endpoint + '?get_next=' + datestype,
                        regBy: $regBy,
                        startOn: $start,
                        success: function(response){
                            var months = ["Jan","Feb","Mar","Apr","May","June","July","August","Sept","Oct","Nov","Dec"],
                            next_start = new Date(response.NEXT_COURSE.start_date),
                            registration_ends = new Date(response.NEXT_COURSE.registration_end);

                            //Start
                            next_start = months[next_start.getUTCMonth()] + ' ' + next_start.getUTCDate();
                            this.startOn.html(next_start);

                            //Register By
                            registration_ends = months[registration_ends.getUTCMonth()] + ' ' + registration_ends.getUTCDate();
                            this.regBy.html(registration_ends);
                        }
                    });
                }

            });
        }
    });  
})(jQuery);