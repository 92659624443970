(function($){
    $(function(){
        var $progList = $('#apus-program-list');

        if ($progList.length) {
            var listOptions = {
                valueNames: [
                    'apus-program-title',
                    { data: ['degrees'] }
                ],
                fuzzySearch: {
                  searchClass: 'apus-program-list-search',
                  location: 0,
                  distance: 100,
                  threshold: 0.4,
                  multiSearch: true
                }
            },
            programList = new List('apus-program-list', listOptions),
            $progFilter = $progList.find('.apus-program-list-filter'),
            $progRow = $progList.find('.apus-program-row'),
            hideClass = 'd-none';

            programList.sort('apus-program-title', { order: "asc" });

            programList.on('sortComplete', function(){
                if ( $('.apus-heading-title button').hasClass('desc') ) {
                    $('.apus-heading-title').attr('aria-sort', 'descending');
                } else {
                    $('.apus-heading-title').attr('aria-sort', 'ascending');
                }
            });

            $progFilter.on('change', function(){
                var selection = $progFilter.val();

                $progRow.removeClass(hideClass);

                if (selection === 'all') {
                    programList.filter();
                } else {
                    programList.filter(function(item){
                        if(item.values().degrees.indexOf(selection) >= 0) { 
                            return true;
                        } else {
                            return false;
                        }
                    });

                    $progRow.each(function(){
                        var $this = $(this);
                        if (!$this.hasClass(selection)) {
                            $this.addClass(hideClass);
                        }
                    });
                }                
            });
        }
    });
})(jQuery);