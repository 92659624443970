
(function($){
    $(function(){

        const getMarkup = function(src, el, $modal){
            $.ajax({
                url: `${src}&api=true`,
                //pull markup from the src url
                success: function(data){
                    $(el).html(data);
                    $(el).find('.apus-student-story-embed').videoStorySetup();
                },
                //Go directly to the link on error
                error: function (err){
                    // $modal.on('show.bs.modal', function(e){
                    //     window.location.href = src;
                    // });
                }
            })
        }

        $.fn.videoStorySetup = function() {
            $this = this;
            if (this.length) {
                var ytParse = function(url){
                    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                    var match = url.match(regExp);
                    return (match&&match[7].length==11)? match[7] : false;
                  },
                  ytEmbed = 'https://www.youtube.com/embed/',
                  $ytLink = $this.find('.apus-yt-link'),
                  ytID = ytParse($ytLink.attr('href'));
                        
    
                $ytLink.on('click', function(e){
                    e.preventDefault();
                    var $that = $(this);

                    $that.next('iframe').attr('src', ytEmbed + ytID + '?autoplay=1');
                    $that.addClass('apus-toggled');
                });
            }
        };

        $('.apus-student-story-modal').on('show.bs.modal', function (event) {
            $this = $(this),
                src = $this.data('source'),
                content = $this.find('.apus-student-story-detail-wrap');

            getMarkup(src, content, $this);
        });
        $('.apus-student-story-modal').on('hidden.bs.modal', function (event) {
            $this = $(this);
            
            $this.find('.apus-student-story-detail-wrap').html('');

        });


        
    });
})(jQuery);