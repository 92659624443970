(function($) {
    $(function() {
        var $body = $('body'),
            $doc = $(document),
            $site = $('#site'),
            $assetClasses = $('.apus-asset-amu, .apus-asset-apu, .apus-asset-default'),

            //Menu drawer
            $drawer = $('#apus-header'), //the off canvas drawer element
            $drawerToggle = $('.apus-menu-btn'), //the toggle of the drawer
            drawerOpen = 'apus-nav-opened', //body class when drawer is open
            $menuBar = $drawer.find('.apus-menu-bar'),
            $headerSearch = $('#apus-header .apus-site-search .apus-search-group'),
            $breadcrumbs = $('#apus-breadcrumbs'),

            //close drawer functions
            closeDrawer = function closeDrawer(toggle, openClass) {
                $body.removeClass(openClass);
                toggle.removeAttr('aria-expanded').attr('title', 'Open Menu').find('span').html('Open Menu');
                $doc.off('keydown.drawerKeys').off('focusin.drawerKeys').off('click.drawerClose');
            },

            //open drawer functions
            openDrawer = function openDrawer(toggle, drawer, openClass) {
                $body.addClass(openClass);
                toggle.attr('aria-expanded', 'true').attr('title', 'Close Menu').find('span').html('Close Menu');

                $doc.on('keydown.drawerKeys', function(e) {
                    var keyCode = e.which;
                    if (keyCode === 27) {
                        closeDrawer(toggle, openClass);
                    }
                }).on('focusin.drawerKeys', function(e) {
                    if ($body.hasClass(openClass) && !$.contains(drawer.get(0), e.target)) {
                        drawer.find('a').eq(0).focus();
                    }
                }).on('click.drawerClose', function() {
                    closeDrawer(toggle, openClass);
                });
            },

            //build a drawer
            buildDrawer = function buildDrawer(toggle, drawer, openClass) {

                toggle.on('click.drawerToggle', function(e) {
                    e.stopPropagation();

                    if ($body.hasClass(openClass)) {
                        closeDrawer(toggle, openClass);
                    } else {
                        openDrawer(toggle, drawer, openClass);
                    }
                });

                drawer.on('click.drawerClick', function(e) {
                    e.stopPropagation();
                });

                var $loginBtn = drawer.find('button.apus-login');

                if ($loginBtn.length) {
                    $loginBtn.on('click.loginClick', function() {
                        closeDrawer(toggle, openClass);
                        $($loginBtn.attr('data-target')).modal('show');
                    });
                }
            },

            //destroy a drawer
            destroyDrawer = function destroyDrawer(toggle, drawer, openClass) {
                closeDrawer(toggle, openClass);
                drawer.off('click.drawerClick');
                toggle.off('click.drawerToggle');
                $(document.activeElement).trigger('blur');

                var $loginBtn = drawer.find('button.apus-login');

                if ($loginBtn.length) {
                    $loginBtn.off('click.loginClick');
                }
            },

            //Main Navigation
            $topLinks = $('#apus-nav-main .apus-top-link'),
            $subLinks = $('#apus-nav-main .apus-sub-link'),
            buildMobileLinks = function buildTopLinks(links, prefix) {
                links.each(function(idx, itm) {
                    var $link = $(itm),
                        $panel = $link.next('.apus-menu-panel'),
                        panelID = prefix + '-' + idx,
                        expanded = 'apus-expanded';

                    $link.attr('role', 'button').attr('aria-controls', panelID).attr('aria-expanded', 'false');
                    $panel.attr('id', panelID);

                    $link.on('click.' + prefix, function(e) {
                        e.preventDefault();

                        $link.attr('aria-expanded', function(index, attr) {
                            return attr === 'true' ? 'false' : 'true';
                        }).toggleClass(expanded);

                        $panel.slideToggle('fast');
                    });
                });
            },

            destroyMobileLinks = function destroyMobileLinks(links, prefix) {
                links.each(function(idx, itm) {
                    var $link = $(itm),
                        $panel = $link.next('.apus-menu-panel'),
                        expanded = 'apus-expanded';

                    $link.removeAttr('role').removeAttr('aria-controls').removeAttr('aria-expanded');
                    $panel.removeAttr('id').removeAttr('style');

                    $link.off('click.' + prefix).removeClass(expanded);
                });

            },

            buildMegaMenu = function buildMegaMenu(links, prefix, menuBar) {
                links.each(function(idx, itm) {
                    var $link = $(itm),
                        $panel = $link.next('.apus-menu-panel'),
                        panelID = prefix + '-' + idx,
                        expanded = 'apus-expanded',
                        closePanel = function closePanel() {
                            $link.attr('aria-expanded', 'false').removeClass(expanded).next('.apus-menu-panel').slideUp('fast');
                            $doc.off('click.megaMenuPanelClick');
                        };

                    $link.attr('role', 'button').attr('aria-controls', panelID).attr('aria-expanded', 'false');
                    $panel.attr('id', panelID);

                    menuBar.on('click.megaMenuClick', function(e) {
                        e.stopPropagation();
                    });

                    $link.on('click.' + prefix, function(e) {
                        e.preventDefault();

                        if (!$link.hasClass(expanded)) {
                            if (links.not($link).hasClass(expanded)) {
                                links.not($link).attr('aria-expanded', 'false').removeClass(expanded).next('.apus-menu-panel').slideUp('fast');
                            }

                            $link.attr('aria-expanded', 'true').addClass(expanded);
                            $panel.slideDown('fast');

                            $doc.on('click.megaMenuPanelClick', function() {
                                closePanel();
                            });

                        } else {
                            closePanel();
                        }
                    });

                    $panel.find('.apus-close-menu').on('click.' + prefix, function() {
                        closePanel();
                    });
                });
            },

            destroyMegaMenu = function destroyMegaMenu(links, prefix, menuBar) {
                links.each(function(idx, itm) {
                    var $link = $(itm),
                        $panel = $link.next('.apus-menu-panel'),
                        expanded = 'apus-expanded';

                    $link.removeAttr('role').removeAttr('aria-controls').removeAttr('aria-expanded');
                    $panel.removeAttr('id').removeAttr('style');

                    menuBar.off('click.megaMenuClick');
                    $doc.off('click.megaMenuPanelClick');
                    $link.off('click.' + prefix).removeClass(expanded);
                    $panel.find('.apus-close-menu').off('click.' + prefix);
                });
            },

            buildSectionNav = function buildSectionNav() {
                var $navTitle = $('#apus-aside-nav-heading .apus-aside-nav-title'),
                    navPanelID = 'apus-aside-nav-panel';

                $navTitle.wrap('<button class="collapsed" data-toggle="collapse" data-target="#' + navPanelID + '" aria-controls="' + navPanelID + '" aria-expanded="false" />');
                $('#' + navPanelID).addClass('collapse');
            },

            destroySectionNav = function destroySectionNav() {
                var $navTitle = $('#apus-aside-nav-heading .apus-aside-nav-title'),
                    navPanelID = 'apus-aside-nav-panel';

                $navTitle.unwrap();
                $('#' + navPanelID).removeClass('collapse');
            },

            addFocusClass = function buildSearchExpand(obj, focusClass) {
                obj.on('focusin', function() {
                    obj.addClass(focusClass);
                });

                obj.on('focusout', function() {
                    obj.removeClass(focusClass);
                });
            },

            removeFocusClass = function removeFocusClass(obj, focusClass) {
                obj.off('focusin').off('focusout').removeClass(focusClass);
            },

            templateBreaks = [{
                    min: 0,
                    max: 1199,
                    enter: function() {
                        //build drawer on mobile resolutions
                        buildDrawer($drawerToggle, $drawer, drawerOpen);
                        buildMobileLinks($topLinks, 'apus-main-menu-panel');
                        buildMobileLinks($subLinks, 'apus-main-submenu-panel');

                    },
                    exit: function() {
                        //destroy drawer on wider resolutions if not used
                        destroyDrawer($drawerToggle, $drawer, drawerOpen);
                        destroyMobileLinks($topLinks, 'apus-main-menu-panel');
                        destroyMobileLinks($subLinks, 'apus-main-submenu-panel');

                    }
                },
                {
                    min: 1200,
                    max: 99999,
                    enter: function() {
                        buildMegaMenu($topLinks, 'apus-main-mega-menu-panel', $menuBar);
                        addFocusClass($headerSearch, 'focus-in');
                    },
                    exit: function() {
                        destroyMegaMenu($topLinks, 'apus-main-mega-menu-panel', $menuBar);
                        removeFocusClass($headerSearch, 'focus-in');
                    }
                }
            ],
            sectionNavBreaks = [{
                min: 0,
                max: 991,
                enter: function() {
                    buildSectionNav();
                },
                exit: function() {
                    destroySectionNav();
                }
            }, ];

        $assetClasses.each(function() {
            var $this = $(this);

            if ($this.is(":hidden")) {
                $this.remove();
            }
        });

        //run breakdance drawer functions
        breakdance(templateBreaks);

        if ($('#apus-aside-nav').length) { breakdance(sectionNavBreaks); }

        if ($breadcrumbs.length) {
            var $programNav = $('.apus-program-layout .apus-program-nav');

            if ($programNav.length) {
                $breadcrumbs.insertAfter($programNav).first();
            } else {
                $breadcrumbs.insertAfter('.apus-page-banner').first();
            }

        }

        $('a[href="#Skip"]').first().parent('div').addClass('apus-skip-link-div');
    });

    $('#apus-site-search-form').on('submit', function(e) {
        if (window.location.href.indexOf('master.apus.edu') > 0 && window.location.href.indexOf('__public/amu-apu') > 0) {
            window.location.href = '/__public/amu-apu/search/index.html#stq=' + this['stq'].value;
        } else if (window.location.href.indexOf('master.apus.edu') > 0 && window.location.href.indexOf('__public/apus') > 0) {
            window.location.href = '/__public/apus/search/index.html#stq=' + this['stq'].value;
        } else if (window.location.href.indexOf('master.apus.edu') > 0 && window.location.href.indexOf('__public/hondros') > 0) {
            window.location.href = '/__public/hondros/search/index.html#stq=' + this['stq'].value;
        } else {
            if (window.location.href.indexOf('-stage/') > 0){
                window.location.href = '/search-stage/index.html#stq=' + this['stq'].value;
            }else{
                window.location.href = '/search/index.html#stq=' + this['stq'].value;
            }
        }
        return false;
    });
})(jQuery);