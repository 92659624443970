//  Function: makeSticky()
//  Author: Dustin Miller & Sarah McVicker
//  Date: 2017-02-02
//  Requires: jQuery


(function($){
  makeSticky = function( options ) {

    var settings = $.extend({
        // default settings
        stickyClass: 'sticky',
        isStickyClass: 'isSticky',
        stuckClass: 'stuck',
        stickyWrapClass: 'stickyWrap',
        cloneClass: 'stickyClone',
        offsetTop: 0,                   //jquery element or number
        offsetBottom: 0                 //jquery element or number
    }, options );

    var $stickyEls = $('.'+ settings.stickyClass),
        $window = $(window);

    if($stickyEls.length){

      //loop and setup each element
      $stickyEls.each(function(){
        var $this = $(this);

        //clean up if it's cloned already
        if($this.next('.'+settings.cloneClass).length){
          $this.next('.'+settings.cloneClass).remove();
          $this
            .removeClass(settings.isStickyClass)
            .css({
              'position': '',
              'width': '',
              'left': '',
              'top': '',
              'bottom': ''
            });
        }

        //clone placeholder
        $this
          .clone()
          .addClass(settings.cloneClass)
          .insertAfter(this).attr('aria-hidden','true');

        //make absolute, calc position from clone
        $this
          .addClass(settings.isStickyClass)
          .css({
            'position': 'absolute',
            'width': $this.next().outerWidth() + 'px',
            'left': $this.next().position().left + 'px',
            'top': $this.next().position().top + 'px'
          });
      });

      //recalculate on resizing since heights could change
      $window.on('resize.'+settings.stickyClass, function(ev) {

        //get elements that aren't clones
        var $stickyEls = $('.'+ settings.stickyClass).not('.'+ settings.cloneClass);

        //loop and calc each element
        $stickyEls.each(function(){
          var $this = $(this),
              $stickWrap = $this.closest('.'+ settings.stickyWrapClass),
              $calcEl = $this.next(),
              calcOffsetTop = calcOffset(settings.offsetTop),
              calcOffsetBottom = calcOffset(settings.offsetBottom),
              stickyStart = 0,
              stickyStop = 0;

          //if wrapper class not found, use body as wrapper
          if(!$stickWrap.length){
            $stickWrap = $('body');
          }

          //calc where to start and stop being sticky
          stickyStart = $calcEl.offset().top - calcOffsetTop,
          stickyStop = $stickWrap.offset().top + $stickWrap.height() - $calcEl.outerHeight() - calcOffsetTop - calcOffsetBottom;

          //save start and stop on element
          $this
            .data('stickyStart', stickyStart)
            .data('stickyStop', stickyStop);

          $tableRow = $this.children('td,th');
          if($tableRow.length){
            //if coulms are found, set column widths to match the table since it will lose correct spacing when it is made fixed
            $tableCloneRow = $calcEl.children('td,th');
            $tableRow.each(function(i){
              $(this).css("width",$tableCloneRow.eq(i).outerWidth() + 'px');
            });
          }

        });

        //trigger scroll on resize
        $window.scroll();
      });

      //scrolling calcs
      $window.on('scroll.'+settings.stickyClass, function(ev) {
        //loop each element
        $stickyEls.each(function(){
          var $this = $(this),
              scrollAmt = $window.scrollTop();
              $stickWrap = $this.closest('.'+ settings.stickyWrapClass),
              calcOffsetTop = calcOffset(settings.offsetTop),
              calcOffsetBottom = calcOffset(settings.offsetBottom);

          //if wrapper class not found, use body as wrapper
          if(!$stickWrap.length){
            $stickWrap = $('body');
          }

          //scroll check
          if(scrollAmt > $this.data('stickyStop')){
            //if past the stopping point, element should be absolute at the bottom offset of the container
            $this.css({
              'width': $this.next().outerWidth() + 'px',
              'left': $this.next().position().left + 'px',
              'bottom': calcOffsetBottom + 'px',
              'top':'',
              'position':'absolute'
            });

          }else if (scrollAmt > $this.data('stickyStart')) {
            //if past the starting point, element should be fixed plus offset
            $this
              .addClass(settings.stuckClass) 
              .css({
                'position': 'fixed',
                'left': $this.next().offset().left + 'px',
                'width': $this.next().outerWidth() + 'px',
                'top': calcOffsetTop + 'px',
                'bottom': ''
              });

          }else{
            //if its above the starting point, position absolutely (position calced from clone)
            $this
              .removeClass(settings.stuckClass)
              .css({
                'width': $this.next().outerWidth() + 'px',
                'left': $this.next().position().left + 'px',
                'bottom': '',
                'top': $this.next().position().top + 'px',
                'position':'absolute'
              });
          }
        });
      });

      $window.resize();
    }
  }

  //returns offset amount whether an element or a number is submitted as the offset
  calcOffset = function(i){
    var returnVar = 0;
    if(isNaN(i) && i.length > 0){
      returnVar = i.outerHeight();
    }else if(!isNaN(i)){
      returnVar = i;
    }

    return Math.round(returnVar);
  }

  //unsticky something, useful with breakdance to choose screen sizes to make things sticky at
  unSticky = function( options ) {
    var settings = $.extend({
        // default settings
        stickyClass: 'sticky',
        isStickyClass: 'isSticky',
        stuckClass: 'stuck',
        cloneClass: 'stickyClone'
    }, options );

    var $window = $(window);

    $window.off('resize.'+settings.stickyClass);
    $window.off('scroll.'+settings.stickyClass);
    $('.'+settings.stickyClass).each(function(){
      $(this).next('.'+settings.cloneClass).remove();
      $(this)
        .removeClass(settings.isStickyClass)
        .removeClass(settings.stuckClass)
        .css({
          'width': '',
          'left': '',
          'bottom': '',
          'top':'',
          'position':''
        });
    });
  }
})(jQuery);
