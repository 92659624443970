(function($){
  $.fn.getURLParameter = function( urlParam ) {
    var urlParams = decodeURIComponent(window.location.search).substring(1).split('&');

    // separate by name
    $(urlParams).each(function(idx,itm) {
      var split = itm.split('=');
      urlParams[idx] = split;
    });

    // look for the provided name and build array
    var results = [];
    $(urlParams).each(function(idx,itm) {
      if (itm[0] == urlParam) {
        results.push(itm[1]);
      }
    });

    return results;
  }; 
})(jQuery);