//allows bootstrapv4 slider to have multiple images displayed. Add "multislide" class to carousel
//https://stackoverflow.com/questions/20346847/bootstrap-carousel-showing-next-and-previous-image
//also has styles associated with it.

if ( $('.carousel.multislide').length ){
  $('.carousel.multislide .carousel-item').each(function(){
      var next = $(this).next();
      if (! next.length) {
        next = $(this).siblings(':first');
      }
      next.children(':first-child').clone().appendTo($(this));
    }).each(function(){
      var prev = $(this).prev();
      if (! prev.length) {
        prev = $(this).siblings(':last');
      }
      prev.children(':nth-last-child(2)').clone().prependTo($(this));
  });
}
