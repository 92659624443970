(function($){
    $(function(){
        var $progFinder = $('#apus-program-finder');

        if ($progFinder.length) {
            var options = {
                valueNames: [
                    'apus-title',
                    'apus-degree',
                    { data: ['duplicate'] },
                    { data: ['degree'] },
                    { data: ['interest'] }
                ],
                searchDelay: 250,
                searchColumns: ['apus-title', 'apus-degree'],
                fuzzySearch: {
                  searchClass: 'apus-search-field ',
                  location: 0,
                  distance: 100,
                  threshold: 0.3,
                  multiSearch: true
                }
            },
            degreeParams = $.fn.getURLParameter('degrees'),
            interestParams = $.fn.getURLParameter('areas'),
            searchParams = $.fn.getURLParameter('search'),
            programList = new List('apus-program-finder', options),
            filterDegrees = false,
            filterInterests = false,
            selectedDegrees = [],
            selectedInterests = [],
            selectedPrograms = [],
            $resultsList = $('#apus-pf-results'),
            $progFilterDegrees = $progFinder.find('#apus-pf-filters input[name="degrees"]'),
            $progFilterInterests = $progFinder.find('#apus-pf-filters input[name="interest"]'),
            $submitBtn = $('#apus-pf-filter-submit'),
            $clearBtn = $('#apus-pf-filter-clear'),
            $searchBox = $('#apus-pf-search-field'),
            $searchBtn = $('#apus-pf-search-btn'),
            $compareCheck = $('#apus-pf-results input[name="compare-program"]'),
            $compareBox = $('#apus-pf-comparisons'),
            $compareSelections = $compareBox.find('#apus-selected-programs'),
            hideDups = function(list) {
                $.each(list.get('duplicate', 'true'), function(){
                    this.hide();
                });
            },
            arraySet = function(itm, array, toggle) {
                var $this = $(itm);
                if ($this.is(':checked')) {
                    array.push($this.val());
                    return true;
                } else {
                    var index = array.indexOf($this.val());
                    if (index > -1) {
                        array.splice(index, 1);
                        return false;
                    }
                }
            },
            filterList = function() {
                
                if (filterInterests && filterDegrees) {

                    programList.filter(function(item){
                        if (selectedDegrees.indexOf(item.values().degree) >= 0 && (selectedInterests.indexOf(item.values().interest) >= 0)){
                            return true;
                        } else {
                            return false;
                        };
                    });

                    
                    programList.sort('apus-title', {order: 'asc'});
                    programList.sort('degree', {order: 'asc'});
                    programList.sort('interest', {order: 'asc'});

                    $('.apus-interest-heading').remove();
                    $('.apus-degree-heading').remove();
                    $.each(selectedInterests, function() {
                        var interestValue = this,
                            headingText = $('label[for="interest-check-'+interestValue+'"]').text();
                        $('#apus-pf-results li[data-interest="'+interestValue+'"]').first().before('<h2 class="h3 apus-interest-heading">'+headingText+'</h3>');

                        $progFilterDegrees.each(function(){
                            var degreeValue = $(this).val(),
                                headingText = $('label[for="degree-check-'+degreeValue+'"]').text();
                            $('#apus-pf-results li[data-degree="'+degreeValue+'"][data-interest="'+interestValue+'"]').first().before('<h3 class="h5 apus-degree-heading">'+headingText+'</h3>');
                        });
                    });

                } else if (filterDegrees) {
                    programList.filter(function(item){
                        if (selectedDegrees.indexOf(item.values().degree) >= 0) {
                            return true;
                        } else {
                            return false;
                        };
                    });

                    programList.sort('apus-title', {order: 'asc'});
                    programList.sort('degree', {order: 'asc'});

                    hideDups(programList);

                    $('.apus-degree-heading').remove();
                    $.each(selectedDegrees, function() {
                        var headingText = $('label[for="degree-check-'+this+'"]').text();
                        $('#apus-pf-results li[data-degree="'+this+'"]').first().before('<h3 class="h5 apus-degree-heading">'+headingText+'</h3>');
                    });
                    
                } else if (filterInterests) {
                    programList.filter(function(item) {
                        if (selectedInterests.indexOf(item.values().interest) >= 0) {
                            return true;
                        } else {
                            return false;
                        };
                    });

                    programList.sort('apus-title', {order: 'asc'});
                    programList.sort('degree', {order: 'asc'});
                    programList.sort('interest', {order: 'asc'});

                    $('.apus-interest-heading').remove();
                    $('.apus-degree-heading').remove();
                    $.each(selectedInterests, function() {
                        var interestValue = this,
                            headingText = $('label[for="interest-check-'+interestValue+'"]').text();
                        $('#apus-pf-results li[data-interest="'+interestValue+'"]').first().before('<h2 class="h3 apus-interest-heading">'+headingText+'</h3>');

                        $progFilterDegrees.each(function(){
                            var degreeValue = $(this).val(),
                                headingText = $('label[for="degree-check-'+degreeValue+'"]').text();
                            $('#apus-pf-results li[data-degree="'+degreeValue+'"][data-interest="'+interestValue+'"]').first().before('<h3 class="h5 apus-degree-heading">'+headingText+'</h3>');
                        });
                    });
                } else {
                    programList.filter();

                    programList.sort('degree', {order: 'asc'});
                    programList.sort('apus-title', {order: 'asc'});

                    hideDups(programList);
                }
                
                
            },
            checkParam = function(array,group) {
                for ( var i = 0; i < array.length; i++ ) {
                    $('#apus-pf-filters .'+group+' input[value="'+array[i]+'"]').trigger('click');
                }
            };

            programList.sort('degree', {order: 'asc'});
            programList.sort('apus-title', {order: 'asc'});
            

            $progFilterDegrees.on('change', function(){
                arraySet(this, selectedDegrees, false);
                if (selectedDegrees.length) {
                    filterDegrees = true;
                } else {
                    filterDegrees = false;
                }

                filterList();
            });

            $progFilterInterests.on('change', function(){
                arraySet(this, selectedInterests, true);
                if (selectedInterests.length) {
                    filterInterests = true;
                } else {
                    filterInterests = false;
                }

                filterList();
            });

            $clearBtn.on('click', function(){
                $('#apus-pf-filters input[name="degrees"]:checked').trigger('click');
                $('#apus-pf-filters input[name="interest"]:checked').trigger('click');
                $searchBox.val('');
                programList.search();

                programList.sort('degree', {order: 'asc'});
                programList.sort('apus-title', {order: 'asc'});

                hideDups(programList);
            });

            $submitBtn.animateScroll({offset: [{offsetEl: $('#apus-header')}]});
            $searchBtn.animateScroll({offset: [{offsetEl: $('#apus-header')}]});

            $compareCheck.on('change', function(){
                var $this = $(this),
                    $progName = $this.data('title'),
                    $progID = $this.val();
                
                    if(arraySet(this, selectedPrograms)){
                        if (!$compareBox.is(':visible')) {
                            $compareBox.collapse('show');
                            $('#apus-pf-comparisons .apus-empty').first().hide();
                        } else {
                            $('#apus-pf-comparisons .apus-empty:visible').first().hide();
                        }
                        
                        $('#apus-pf-comparisons .apus-empty').first().before('<li id="apus-selected-program-'+$progID+'" class="apus-populated">'+$progName+'</li>');
                        
                        $('#apus-selected-program-'+$progID).on('click', function(){
                            $compareCheck.filter(function(){return this.value==$progID}).prop('checked', false).trigger('change');
                        });
                    } else {
                        
                        $('#apus-selected-program-'+$progID).remove();
                        $('#apus-pf-comparisons .apus-empty').not(':visible').last().show();
    
                        if (!selectedPrograms.length) {
                            $compareBox.collapse('hide');
                        }
                    }

                $compareSelections.val(selectedPrograms.toString());

                if (selectedPrograms.length >= 3) {
                    $compareCheck.not(':checked').attr('disabled', 'disabled');
                } else {
                    $compareCheck.removeAttr('disabled');
                }
            });

            //Reset filters if navigating back to page
            $progFinder.find('input[type="checkbox"]').prop('checked', false);
            $searchBox.val('');

            //Hide Duplicate entries on inital load
            hideDups(programList);

            checkParam(degreeParams, 'apus-level');
            checkParam(interestParams, 'apus-interest');
            

            if (searchParams.length) {
                $searchBox.val(searchParams);
                programList.search(searchParams);
            }

            if (programList.matchingItems.length == 0) {
                    
                if (!$resultsList.next('p.apus-empty-results').length) {
                    $resultsList.after('<p class="apus-empty-results">No programs match selected criteria.</p>');
                }
                
            } else {
                $resultsList.next('p.apus-empty-results').remove();
            }

            //Update the display of list groups on list filter or search update
            programList.on('searchStart', function(){
                $progFinder.addClass('apus-loading');
            });

            programList.on('searchComplete', function(){
                if (filterInterests) {
                    programList.sort('degree', {order: 'asc'});
                    programList.sort('apus-title', {order: 'asc'});
                    programList.sort('interest', {order: 'asc'});

                    $('.apus-interest-heading').remove();
                    $('.apus-degree-heading').remove();
                    $.each(selectedInterests, function() {
                        var interestValue = this,
                            headingText = $('label[for="interest-check-'+interestValue+'"]').text();
                        $('#apus-pf-results li[data-interest="'+interestValue+'"]').first().before('<h2 class="h3 apus-interest-heading">'+headingText+'</h3>');

                        $progFilterDegrees.each(function(){
                            var degreeValue = $(this).val(),
                                headingText = $('label[for="degree-check-'+degreeValue+'"]').text();
                            $('#apus-pf-results li[data-degree="'+degreeValue+'"][data-interest="'+interestValue+'"]').first().before('<h3 class="h5 apus-degree-heading">'+headingText+'</h3>');
                        });
                    });
                } else {
                    programList.sort('degree', {order: 'asc'});
                    programList.sort('apus-title', {order: 'asc'});

                    hideDups(programList);

                    if (filterDegrees) {
                        $.each(selectedDegrees, function(){
                            var headingText = $('label[for="degree-check-'+this+'"]').text();
                            $('#apus-pf-results li[data-degree="'+this+'"]').first().before('<h3 class="h5 apus-degree-heading">'+headingText+'</h3>');
                        });
                    }
                }
            });

            programList.on('filterStart', function(){
                $progFinder.addClass('apus-loading');
            });

            programList.on('updated', function(){
                $progFinder.removeClass('apus-loading');
                
                if (programList.matchingItems.length == 0) {
                    
                    if (!$resultsList.next('p.apus-empty-results').length) {
                        $resultsList.after('<p class="apus-empty-results">No programs match selected criteria.</p>');
                    }
                    
                } else {
                    $resultsList.next('p.apus-empty-results').remove();
                }
            });
        }
    });
})(jQuery);