(function($){
    $(function(){
        var $dynamicPanelData = $('.apus-dynamic-panel-set');

        if ($dynamicPanelData.length) {

            $dynamicPanelData.each(function(){
                var $this = $(this);
                var gridId = $this.find('.dynamic-panels').attr('id');
                var containerEl = document.querySelector('#' + gridId);
                var targetSelector = '.mix';
                var activeHash = '';
                var groupName = $this.find('.panel-filter-wrap').attr('data-filter-group');

                /**
                * Deserializes a hash segment (if present) into in an object.
                *
                * @return {object|null}
                */

                var deserializeHash = function() {
                    var hash    = window.location.hash.replace(/^#/g, '');
                    var obj     = null;
                    var groups  = [];

                    if (!hash) return obj;

                    obj = {};
                    groups = hash.split('&');

                    groups.forEach(function(group) {
                        var pair = group.split('=');
                        var groupName = pair[0];

                        obj[groupName] = pair[1].split(',');
                    });

                    return obj;
                }

                /**
                * Serializes a uiState object into a string.
                *
                * @param   {object}    uiState
                * @return  {string}
                */

                var serializeUiState = function(uiState) {
                    var output = '';

                    for (var key in uiState) {
                        var values = uiState[key];

                        if (!values.length) continue;

                        output += key + '=';
                        output += values.join(',');
                        output += '&';
                    };

                    output = output.replace(/&$/g, '');

                    return output;
                }

                /**
                * Gets the group name
                *
                * @return {string}
                */

                var getGroupName = function() {
                    return groupName;
                }

                /**
                * Constructs a `uiState` object using the
                * `getFilterGroupSelectors()` API method.
                *
                * @return {object}
                */

                var getUiState = function() {
                    // NB: You will need to rename the object keys to match the names of
                    // your project's filter groups these should match those defined
                    // in your HTML.

                    var uiState = {};
                    uiState[groupName] = mixer.getFilterGroupSelectors(groupName).map(getValueFromSelector);

                    // Show current queries
                    //$("#query").text(mixer.getFilterGroupSelectors(groupName).map(getValueFromSelector));
                    return uiState;
                }

                /**
                * Updates the URL hash whenever the current filter changes.
                *
                * @param   {mixitup.State} state
                * @return  {void}
                */

                var setHash = function(state) {
                    var selector = state.activeFilter.selector;

                    // Construct an object representing the current state of each
                    // filter group

                    var uiState = getUiState();

                    // Create a URL hash string by serializing the uiState object

                    var newHash = '#' + serializeUiState(uiState);

                    if (selector === targetSelector && window.location.href.indexOf('#') > -1) {
                        // Equivalent to filter "all", and a hash exists, remove the hash

                        activeHash = '';

                        history.replaceState(null, document.title, window.location.pathname);
                    } else if (newHash !== window.location.hash && selector !== targetSelector) {
                        // Change the hash

                        activeHash = newHash;

                        history.replaceState(null, document.title, window.location.pathname + newHash);
                    }
                }

                /**
                * Updates the mixer to a previous UI state.
                *
                * @param  {object|null}    uiState
                * @param  {boolean}        [animate]
                * @return {Promise}
                */

                var syncMixerWithPreviousUiState = function(uiState, animate) {
                    var groupFilterName = (uiState && uiState[groupName]) ? uiState[groupName] : [];

                    mixer.setFilterGroupSelectors(groupName, groupFilterName.map(getSelectorFromValue));

                    // Parse the filter groups (passing `false` will perform no animation)
                    return mixer.parseFilterGroups(animate ? true : false);
                }

                /**
                * Converts a selector (e.g. '.green') into a simple value (e.g. 'green').
                *
                * @param   {string} selector
                * @return  {string}
                */

                var getValueFromSelector = function(selector) {
                    return selector.replace(/^./, '');
                }

                /**
                * Converts a simple value (e.g. 'green') into a selector (e.g. '.green').
                *
                * @param   {string} selector
                * @return  {string}
                */

                var getSelectorFromValue = function(value) {
                    return '.' + value;
                }

                var uiState = deserializeHash();

                // Instantiate MixItUp

                var mixer = mixitup(containerEl, {
                    multifilter: {
                        enable: true,
                    },
                    "animation": {
                    "duration": 500,
                    "effects": "fade scale(0.01) translateZ(100px)"
                    },
                    callbacks: {
                        onMixStart: function(state, futureState) {
                        //console.log(futureState.activeFilter.selector);
                        },
                        onMixEnd: setHash // Call the setHash() method at the end of each operation
                    },
                    controls: {
                        toggleDefault: 'none'
                    },
                    load: {
                        filter: 'none',
                        sort: 'id:asc'
                    }
                });

                if (uiState) {
                    // If a valid uiState object is present on page load, filter the mixer
                    syncMixerWithPreviousUiState(uiState);
                }

            });

            //reset filters on refresh
            $(window).on('beforeunload', function(){
            $('.panel-filter').val('');
            });
        }
    });
})(jQuery);