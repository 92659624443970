(function($){
    $.fn.animateScroll = function(options){
      // eslint-disable-next-line no-unused-vars
      var settings = $.extend({
        // default settings
        scrollToEl: this,
        speed: 600,
        offset: [
          {
            offsetSize: 0,
            offsetEl: null
          }
        ]
      }, options);
  
      this.on('click', function(e){
        e.preventDefault();
  
        var offset = 0,
            scrollToEl = $(this).attr('href'),
            $el = $(scrollToEl);
  
        $.each(settings.offset, function(i, val){
            offset = 0;
            
            if(('offsetSize' in val)){
              offset = val.offsetSize;
            }
            if(('offsetEl' in val) && val.offsetEl.length){
              var offsetEl =  val.offsetEl.first(),
                  offsetElSize = offsetEl.outerHeight(true);
  
              offset += offsetElSize;
            }
        });
  
        $('html, body').animate({scrollTop: $el.offset().top - offset}, settings.speed);
        $el.focus();
      });
  
      return this;
    }; 
  })(jQuery);