(function($){
    $(function(){
        var $shiftHero = $('.apus-shift-hero');

        if ($shiftHero.length) {
            var $amu = $shiftHero.find('.apus-shift-block.amu'),
                $apu = $shiftHero.find('.apus-shift-block.apu'),
                amuHover = 'apus-hover-amu',
                apuHover = 'apus-hover-apu';

            $amu.on('mouseenter', function(){
                $shiftHero.addClass(amuHover);
            });

            $amu.on('mouseleave', function(){
                $shiftHero.removeClass(amuHover);
            });

            $apu.on('mouseenter', function(){
                $shiftHero.addClass(apuHover);
            });

            $apu.on('mouseleave', function(){
                $shiftHero.removeClass(apuHover);
            });
        }
    });  
})(jQuery);