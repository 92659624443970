String.prototype.toTitleCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

$('a').each(function (index, link) {
    var id_check = findID(this);
    id_check.then(function (res) {
        link.dataset.id = res;
    }, function (err) {
    })
})

function findID(root, level, promise, set) {
    var level = level || 0;
    set = set || [];
    var promise = promise || $.Deferred();
    var parentId = $(root).parent().attr('id');
    if (!$(root).parent().attr('id')) {
        level++;
        if ($(root).parent().length > 0) {
            findID($(root).parent(), level, promise);
        } else {
            promise.reject("Id not found");
        }
    } else {
        promise.resolve($(root).parent().attr('id'));
    }
    return promise.promise();
};

$("#tf").click(function () {
    $("#apus-main-menu-panel-2").css("display", "block");
    $("#apus-main-submenu-panel-7").css("display", "block");
});
$("#about").click(function () {
    $("#apus-main-menu-panel-4").css("display", "block");
    $("#apus-main-submenu-panel-12").css("display", "block");
});
$("#support").click(function () {
    $("#apus-main-menu-panel-5").css("display", "block");
    $("#apus-main-submenu-panel-13").css("display", "block");
});



