(function($){
  $(function(){
    var $videoStory = $('.apus-video-story, .apus-student-story-detail .apus-student-story-embed');

    if ($videoStory.length) {
      var ytParse = function(url){
          var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
          var match = url.match(regExp);
          return (match&&match[7].length==11)? match[7] : false;
        },
        ytEmbed = 'https://www.youtube.com/embed/';

      $videoStory.each(function(){
        var $this = $(this),
            $ytLink = $this.find('.apus-yt-link'),
            ytID = ytParse($ytLink.attr('href'));
            

        $ytLink.on('click', function(e){
          e.preventDefault();
          var $that = $(this);

          $that.next('iframe').attr('src', ytEmbed + ytID + '?autoplay=1');
          $that.addClass('apus-toggled');
        });
      });
    }
  });  
})(jQuery);