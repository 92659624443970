(function($){
    $(function(){
        $('.apus-accordion-show-all').bind( "click", function() {
            var toggleType = 'hide',
                $accordions = $(this).parents('.apus-accordion').find('.apus-accordion-item > .apus-content');

            if($accordions.not('.show').length){
                toggleType = 'show';
            }
            $accordions.collapse(toggleType);
        });
    });  
})(jQuery);