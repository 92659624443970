(function($){
    $(function(){
        var $journey = $('.apus-journey'),
            journeyBreaks = [
                {
                    min: 768,
                    max: 99999,
                    enter: function(){
                        $(window).on('resize.journey', function(){
                            $journey.each(function(){
                                var $this = $(this),
                                    $step = $this.find('.apus-timeline ul > li'),
                                    $timeline = $this.find('.apus-timeline');
                                    height = 0;
                                
                                $step.each(function(){
                                    var $that = $(this);
                                    if ($that.outerHeight() > height) { height = $that.outerHeight(); }
                                });

                                $timeline.css('height', height*2 + 'px');
                            });
                        });

                        $(window).trigger('resize');
                    },
                    exit: function(){
                        $(window).off('resize.journey');
                        $journey.each(function(){
                            $(this).find('.apus-timeline').removeAttr('style');
                        });
                    }
                }
            ];
        
        if ($journey.length) {
            $journey.each(function(i){
                var $this = $(this),
                    $quoteStage = $this.find('.apus-quotes-stage')
                    $quote = $quoteStage.find('ul > li'),
                    $step = $this.find('.apus-timeline ul > li');

                if($quote.length > 1){
                    $this.attr('id', 'journey-billboard-' + i);

                    $quote.each(function(x){
                        var $that = $(this);

                        $that.attr('id', 'journey-billboard-' + i + '-quote-' + x);
                    });

                    $step.each(function(x){
                        var $that = $(this),
                            controls = 'journey-billboard-' + i + '-quote-' + x;
                        
                        $that.attr('aria-controls', controls);

                        $that.on('click', function(e){
                            $target = $(this);

                            if (!$target.hasClass('apus-active')) {
                                $this.find('.apus-timeline .apus-active').removeClass('apus-active');
                                $target.addClass('apus-active');
                                $quoteStage.find('.apus-active').removeClass('apus-active').fadeOut('fast').promise().done(function(){
                                    $('#' + controls).addClass('apus-active').fadeIn('fast');
                                });
                            }
                        });
                    });
                }

                
            });

            breakdance(journeyBreaks);
            
        }
    });  
})(jQuery);